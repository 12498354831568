import loadable from '@loadable/component';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useBusinessIntroData from 'src/cms/data/business/useBusinessIntroData';
// sections
import Info from './sections/Info';
import { PageProps } from 'gatsby';
import { FC } from 'react';

const FullService = loadable(() => import('./sections/FullService'));
const DiscoveryPhase = loadable(() => import('./sections/DiscoveryPhase'));
const WhatWeUse = loadable(() => import('./sections/WhatWeUse'));

const Business: FC<PageProps> = () => {
  // hooks
  const { title, mobileTitle, description } = useBusinessIntroData();

  return (
    <PageLayout>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='smallBusinessDrawing'
      />
      <Info />
      <FullService />
      <DiscoveryPhase />
      <WhatWeUse />
    </PageLayout>
  );
};

export default Business;
