// hooks
import useBusinessMetaData from 'src/cms/data/business/useBusinessMetaData';
// components
import Seo from 'src/components/Seo';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const businessMetaData = useBusinessMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={businessMetaData?.title}
      description={businessMetaData?.description}
      schema={businessMetaData?.schema}
    />
  );
};

export { default } from './Business';
